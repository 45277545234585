import { notFound } from "next/navigation";

export const config = {
  fizios: {
    name: "Fizios",
    logo: "/assets/fizios/logo.svg",
    favicon: "/assets/fizios/favicon.ico",
    singular: "Pacijent",
    plural: "Pacijenti",
    whom: "pacijenta",
    allowedDomains: [
      "app.fizios.ba",
      "fizios.localhost:3000",
      "localhost:3000",
      "fizios.rezervator.ba",
      "fizios.dev.rezervator.ba",
    ],
  },
};

export const serverSideConfigLoader = (host: string) => {
  for (const key in config) {
    // @ts-ignore
    const appConfig = config[key];
    if (appConfig.allowedDomains && appConfig.allowedDomains.includes(host)) {
      return appConfig;
    }
  }

  return notFound();
};

export const clientSideConfigLoader = (host: string) => {
  for (const key in config) {
    // @ts-ignore
    const appConfig = config[key];
    if (appConfig.allowedDomains && appConfig.allowedDomains.includes(host)) {
      return appConfig;
    }
  }
  return false;
};
